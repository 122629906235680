<template>
    <div>
        <div class="pb-10 h-100 overflow-auto bottom-padding ml-2 mr-2">
            <div class="d-flex flex-row flex-wrap justify-content-between align-items-center">
                <div class="breadcrumbactive">
                    <div>
                        <span class="pointer" @click="goToListView">Board Member</span>
                        <div v-if="$route.path.startsWith('/board/createboard/cmpid/')">Create New</div>
                        <div v-if="$route.path.startsWith('/board/editboard/cmpid/')">Edit</div>
                    </div>
                </div>
            </div>
            <div class="wizard-bg">
                <div class="pb-6 px-0 mb-0">
                    <div v-if="$route.path.startsWith('/board/editboard/cmpid/')" class="text-center fs-14 fw-600" style="color: #003cb9">
                        Edit Board Member
                    </div>
                    <div v-if="$route.path.startsWith('/board/createboard/cmpid/')" class="text-center fs-14 fw-600" style="color: #003cb9">
                        Add New Board Member
                    </div>
                    <div class="d-flex justify-content-center align-items-center w-100 mt-6">
                        <span :class="basicInfo ? 'selectedCircle' : permissionProduct  ? 'finishedCircle' : 'unfinishedCircle'"
                            class="mx-5">
                            1
                            <span :class="basicInfo ? 'finishedCircleText' : 'unfinishedCircleText'">
                                Basic Info
                            </span>
                        </span>
                        <span class="unfinishedDash"></span>
                        <span
                            :class="permissionProduct ? 'selectedCircle' :  'unfinishedCircle'"
                            class="mx-5">
                            2
                            <span :class="permissionProduct ? 'finishedCircleText' : 'unfinishedCircleText'">
                                Permission Product
                            </span>
                        </span> 
                    </div>
                </div>
            </div>
            <div v-if="ajax_call_in_progress" class="d-flex align-items-center justify-content-center">
                <half-circle-spinner :animation-duration="1000" :size="60" color="#00448b" />
            </div>
            <div v-else>
                <div :class="{'d-none':!basicInfo}">
                    <basic-info :consultant_data="consultant_data" @switchWizard="switchWizard"></basic-info>
                </div>
                <div :class="{'d-none':!permissionProduct}">
                    <product-permission :comp_data="comp_data" :consultant_data="consultant_data" @switchWizard1="switchWizard1"></product-permission>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import consultant from '../mixins/consultant';
    const BasicInfo = () => import('./BasicInfo');
    const ProductPermission = () => import('./ProductPermission')
    import {HalfCircleSpinner} from 'epic-spinners';
    export default {
        mixins: [consultant],
        data() {
            return{
                basicInfo:true,
                permissionProduct:false,
                basicInformationTab: false,
                permissionProductTab : false,
                consultant_data: {},
                ajax_call_in_progress: false,
                comp_data: {}
            }
        },
        components: {
            BasicInfo,
            ProductPermission,
            HalfCircleSpinner
        },
        // beforeRouteLeave (to, from, next) {
        //     let message = "<div class='d-block'>Unsaved Outlet Data would be lost, Do you want to proceed?</div>";
        //     let options = {
        //         html: true,
        //         loader: false,
        //         reverse: false,
        //         okText: 'Yes',
        //         cancelText: 'No',
        //         animation: 'fade',
        //         type: 'basic',
        //         verification: 'continue', 
        //         verificationHelp: 'Type "[+:verification]" below to confirm',
        //         backdropClose: true,
        //     };
        //     this.$dialog.confirm(message, options)
        //     .then(function () {
        //         next();
        //     })
        //     .catch(function () {
        //         next(false);
        //     });
        // },
        methods: {
            switchWizard(){
                this.permissionProduct = true
                this.basicInfo = false
                this.permissionProductTab = true
                this.basicInformationTab = false
            },
            goToListView(){
                this.$router.push('/board/listview/cmpid/'+this.$route.params.company_id)
            },
            switchWizard1(){
                this.permissionProduct = false
                this.basicInfo = true
                this.permissionProductTab = false
                this.basicInformationTab = true
            },
            async getConsultantData(id){
                this.ajax_call_in_progress = true;
                try {
                    let response = await this.getConsultantById(id)
                    this.consultant_data = response.response
                    this.ajax_call_in_progress = false;
                }
                catch (error) {
                    this.ajax_call_in_progress = false;
                }
            },
            async getCompData() {
                try {
                    let response = await this.getCompanyById(this.$route.params.company_id);
                    if (response.status_id == 1) {
                        let res_data = response.response
                        this.comp_data = res_data
                    }
                }
                catch(err) {
                    // console.log(err)
                }
            },
        },
        mounted(){
            this.getCompData();
            if(this.$route.path.startsWith('/board/editboard/cmpid')) { 
                this.getConsultantData(this.$route.params.id);
            }
        }
    }

</script>

<style scoped>
    .progress-topic{
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.5;
        letter-spacing: 0.47px;
        text-align: left;
        color: #003cb9;
    }
    .wizard-bg{
        background-color: #fff;
        border-radius: 6px;
        /* height: 150px;
         */
        padding-top: 20px;
        padding-bottom: 20px;
    }
    .unfinishedCircle {
        background: #e8e8e8;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: rgba(0, 0, 0, 0.32);
        font-weight: 500;
    }

    .finishedCircle {
        background: #128807;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .selectedCircle {
        background: #ff9a06;
        border-radius: 50%;
        height: 50px;
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: 500;
    }

    .finishedCircleText {
        position: absolute;
        font-size: 14px;
        color: #303031;
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedCircleText {
        position: absolute;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.32);
        margin-top: 6em;
        font-weight: 600;
    }

    .unfinishedDash {
        color: #e8e8e8;
        font-size: 20px;
        border-top: 2px dashed rgba(0, 0, 0, 0.32);
        width: 25%
    }

    .finishedDash {
        color: #128807;
        font-size: 20px;
        border: 1px dashed #128807;
        width: 100px;
    }
    /* @media screen and (max-width: 1200px) {
        .wizard-bg{
            height: 160px !important;
            padding-left: 30px;
            padding-right: 33px;
        }
    } */
    @media only screen and (max-width: 768px){
        .bottom-padding{
            padding-bottom: 150px !important;
            margin-right: 5px !important;
        }
    }
</style>